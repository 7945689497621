import { COMMANDS } from '../snippet_processor/Commands';
import { featureUsage } from '../components/Version/limitations';
import { decompressDelta } from '../delta_proto/DeltaProto';
import { createDom } from '../snippet_processor/SnippetProcessor';
import { Environment } from '../snippet_processor/DataContainer';
import { addAddonAttributesNoProcessing, intersectPlatformSupport } from './AddAddonOptions';

/**
 * @param {AddonOptionsType} addonOptions existing options obtained from addAddonAttributesNoProcessing
 * @param {Pick<SnippetObjectType, 'content'>} data 
 * 
 * @return {Promise<void>}
 */
export async function addAddonAttributesWithProcessing(addonOptions, data) {
  if (data.content) {
    let env = new Environment({}, { type: 'text', stage: 'preview' });
    let dom = await createDom(
      data.content.delta.ops ? data.content.delta : decompressDelta(data.content.delta.toUint8Array ? data.content.delta.toUint8Array() : data.content.delta),
      env
    );
    let usage = await featureUsage(dom, env);

    addonOptions.usedLambdaWhitelist = usage.LAMBDAS;

    if (usage['CONNECTED']) {
      addonOptions.connected = true;
    }

    if (usage.SITE_SELECTORS?.length) {
      addonOptions.platforms = intersectPlatformSupport(addonOptions.platforms, { browser: true });
    }

    // We have approved grants for the entire addon group
    // but we want it for a particular snippet in that group
    // as well to show or hide that command in AI Blaze
    addonOptions.commands = usage['COMMANDS'];

    if (!addonOptions.invalidInAttribute) {
      for (let command of usage.COMMANDS) {
        let def = COMMANDS[command.toUpperCase()];
        if (def) {
          if (def.invalidIn === 'attribute'
              // if, repeat and notes can be used in addons in attributes
              && !(def.subType && (def.subType.startsWith('if_') || def.subType.startsWith('repeat_') || def.subType.startsWith('note_')))) {
            addonOptions.invalidInAttribute = true;
          }
        }
      }
    }
  }
}

/**
 * @param {string} addonNamespace
 * @param {Pick<SnippetObjectType, 'options'|'shortcut'|'content'>} data 
 * 
 * @return {Promise<AddonOptionsType>}
 */
export async function addAddonAttributes(addonNamespace, data) {
  const addonOptions = await addAddonAttributesNoProcessing(addonNamespace, data);
  await addAddonAttributesWithProcessing(addonOptions, data);

  return addonOptions;
}