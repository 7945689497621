import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { dialogConfigs } from '../DataDialog/dialogConfigs';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PublishButton from '../Publish/PublishButton';
import { deleteSnippet } from '../../data';
import T from '@mui/material/Typography';
import {
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  TextField,
  Tooltip
} from '@mui/material';
import ConfigureIcon from '@mui/icons-material/SettingsOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ChangeHistoryButton from '../ChangeHistory/ChangeHistoryButton';
import { COMMANDS } from '../../snippet_processor/Commands';
import { useDispatch } from 'react-redux';
import HelpIcon from '@mui/icons-material/HelpOutline';
import { useTypedSelector, useTypedSelectorDeepEquals } from '../../hooks';
import { checkOrg, CURRENT_PLATFORM, isElectronApp } from '../../flags';
import AddonIcon from '@mui/icons-material/WidgetsOutlined';
import Box from '@mui/material/Box';
import { sync, updateGroupAddonPermissions, updateGroupPermissions } from '../../Sync/syncer';
import { getState } from '../../getState';
import { ICON_MAPPING } from './EmbeddedCommand/embedded_utilities';
import moment from 'moment';
import { ChromeChip, BetaChip, ProChip } from '../Version/VersionChip';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import FunctionsIcon from '@mui/icons-material/Functions';
import { Link, useHistory } from 'react-router-dom';
import { getPackSupportedPlatforms } from '../Addons/supported_platforms';
import { standardKeys } from './standardKeys';
import { NameChip } from './TypedItems';
import CommentsButton from '../Comments/CommentsButton';
import { SnippetEditorContext } from '../Snippet/SnippetWrapper';
import { CATEGORIES } from './SuggestionList';
import { FUNCTIONS } from '../../snippet_processor/Equation';
import equalsES6 from 'fast-deep-equal/es6';
import { MenuItemTextWithChip } from '../AttributeEditors/AttributeEditors';
import { updateSitePermissions } from '../PageBlaze/site_db_utilities';
import { CODE_BLOCK_INVALID_ATTRS, commandAttributes, field } from './editor_utilities';
import { showTrashConfirmation } from '../../message';
import FormControlLabel from '@mui/material/FormControlLabel';
import { aiBlazeCommandsFilter, isAiBlaze, snippetOrPrompt } from '../../aiBlaze';
import useHotkey from '../../hotkeys_hook';
import { fullAppName } from '../../raw_flags';


function dateEntry(msg, momentStr) {
  return {
    plain_text: msg,
    text: <div style={{
      display: 'flex',
      alignItems: 'center'
    }}><div style={{
        flex: 1,
        paddingRight: 10,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      }}>{msg}</div><span className="ex">{moment().format(momentStr)}</span></div>,
    action: ({ insert }) => insert('{time: ' + momentStr + '}'),
    id: 'moment:' + momentStr
  };
}


  
const DATE_AND_TIME_ITEMS = [
  dateEntry('Long full date', 'MMMM Do YYYY'),
  dateEntry('Short full date', 'YYYY-MM-DD'),
  '-',
  dateEntry('Long year', 'YYYY'),
  dateEntry('Short year', 'YY'),
  '-',
  dateEntry('Long month', 'MMMM'),
  dateEntry('Long day', 'dddd'),
  '-',
  dateEntry('Month (01-12)', 'MM'),
  dateEntry('Day (01-31)', 'DD'),
  '-',
  dateEntry('Month (1-12)', 'M'),
  dateEntry('Day (1-31)', 'D'),
  '-',
  dateEntry('24-hour time', 'HH:mm'),
  dateEntry('12-hour time', 'hh:mm A'),
  '-',
  {
    text: 'Date in 1 day',
    action: ({ insert }) => insert('{time: YYYY-MM-DD; shift=1D}'),
    id: 'shift:time'
  },
  {
    text: 'Date in 1 week',
    action: ({ insert }) => insert('{time: YYYY-MM-DD; shift=1W}'),
    id: 'shift:time'
  }, {
    text: 'Date in 1 month',
    action: ({ insert }) => insert('{time: YYYY-MM-DD; shift=1M}'),
    id: 'shift:time'
  },
  '-',
  {
    text: 'Next monday',
    action: ({ insert }) => insert('{time: dddd YYYY-MM-DD; shift=+1D >MON}'),
    id: 'shift:time'
  },
  {
    text: 'Start of next month',
    action: ({ insert }) => insert('{time: dddd YYYY-MM-DD; shift=>M +1D}'),
    id: 'shift:time'
  },
];



function dbCommandAction(data) {
  if (import.meta.env.VITE_APP_APP_TYPE === 'PAGE') {
    // BUILD NOTICE: this block will not be included in Text Blaze bundles,
    updateSitePermissions(data[1].value, data[0].value);
    return;
  }

  // BUILD NOTICE: the next block will not be included in Page Blaze builds
  let snippetId = getState().uiState.selected;
  if (snippetId === 'new') {
    // we're currently in a draft mode, don't update permissions
    return;
  }
  let snippet = sync.getSnippetById(snippetId);
  updateGroupPermissions(snippet.group_id, data[1].value, data[0].value);
}

  
/**
 * @param {string} commandType
 * @param {string} label
 * @param {string} description
 */
function genDBDynamicItem(commandType, label, description) {
  return {
    pro: commandType !== 'insert', // dbinsert is free
    invalidInAttribute: true,
    description,
    icon: 'db',
    text: label,
    alias: 'db' + commandType.toLowerCase() + ' space table data',
    action: ({ dialog }) => dialog('DB' + commandType.toUpperCase()).then(dbCommandAction),
    id: `db${commandType}`,
    hideForAttribute: /** @type {'simple'} */ ('simple'),
    hideDuringAdd: true
  };
}

function getKeyCommands() {
  /** @type {({ text: string|JSX.Element, action: function, id: string }|'-')[]} */
  let standardObjects = standardKeys.map(({ code, name, platforms }) => {
    let commandString = `key:${code}`,
      displayStr = `${name} key`,
      /** @type {string|JSX.Element} */
      text = displayStr;

    text = <MenuItemTextWithChip platforms={platforms} name={displayStr} />;

    return {
      text, 
      action: ({ insert }) => insert(`{${commandString}}`),
      id: commandString,
    };
  });
  
  return standardObjects.concat([
    '-',
    ...(CURRENT_PLATFORM !== 'browser' ? [{
      text: <MenuItemTextWithChip platforms={{ [CURRENT_PLATFORM]: true }} name="Function key press..."  />,
      action: ({ dialog }) => dialog('KEY', { '%positional': 'F1' }),
      id: 'custom-key'
    }] : []),
    {
      text: 'Custom key press...',
      action: ({ dialog }) => dialog('KEY'),
      id: 'custom-key'
    }
  ]);
}

/**
 * @type {SectionChildType[]}
 */
const FUNCTIONS_SECTIONS = [];
const FUNCTIONS_LIST = Object.keys(FUNCTIONS).map(f => ({
  name: f,
  ...FUNCTIONS[f]
})).filter(f => !!f.description);
Object.keys(CATEGORIES).forEach(c => {
  const categoryFunctions = FUNCTIONS_LIST.filter(fn => fn.category === c);
  if (!categoryFunctions.length) {
    return;
  }
  FUNCTIONS_SECTIONS.push({
    text: CATEGORIES[c],
    description: '',
    icon: c,
    alias: '',
    onlyForFormula: true,
    children: categoryFunctions.map(fn => ({
      plain_text: fn.name,
      alias: fn.alias,
      text: <div style={{
        display: 'flex',
        alignItems: 'center'
      }}>
        <div style={{
          flex: 1,
          paddingRight: 10,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          minWidth: '40%'
        }}>{fn.name}</div>
        <span className="ex" style={{
          maxWidth: 200,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }}>{fn.placeholder}</span>
      </div>,
      action: ({ insert }) => insert(fn.placeholder),
      description: fn.description,
      id: 'fn:' + fn.name
    })),
    id: 'fn-category:' + c
  });
});

/** 
 * @typedef {{ text: string, description: string|JSX.Element, testId?: string, pro?: boolean, invalidInAttribute?: boolean, action?: function, icon: string|(() => JSX.Element), alias?: string, children?: any[], id: string, platforms?: SupportedPlatforms, noPage?: boolean, hideForAttribute?: 'always'|'simple', onlyForFormula?: boolean, hideDuringAdd?: boolean, isAddon?: boolean }} SectionChildType
 * @typedef {{ title: string, title2?: string, id?: string, constant?: boolean, onlyExactMatch?: boolean, children: SectionChildType[]}} SectionType */
/** @type {SectionType[]} */
const SECTIONS = [
  {
    title: 'Basic commands',
    children: [
      {
        text: 'Date/Time',
        description: 'Insert date and time',
        testId: 'date-button',
        icon: 'datetime',
        alias: 'date time month year day seconds minutes hours weeks',
        children: DATE_AND_TIME_ITEMS,
        id: 'time'
      },
      {
        text: 'Clipboard',
        description: 'Insert clipboard content',
        action: ({ insert }) => insert('{clipboard}'),
        icon: 'clipboard',
        id: 'clipboard',
        alias: 'copy paste clipboard'
      },
      {
        text: 'Place cursor',
        action: ({ insert }) => insert('{cursor}'),
        description: 'Cursor location after insertion',
        id: 'cursor',
        alias: 'caret',
        icon: () => <div style={{ width: '.65em', height: '.65em', position: 'relative', left: 7, top: -8 }}>{ICON_MAPPING['cursor']}</div>,
        hideForAttribute: 'always'
      }
    ]
  }, {
    title: 'Forms',
    title2: 'Formulas',
    id: 'forms',
    children: [
      {
        text: 'Text field',
        description: 'Single line text field',
        icon: 'formsingle_line',
        alias: 'formtext input',
        pro: true,
        invalidInAttribute: true,
        action: ({ dialog }) => dialog('FORMTEXT'),
        id: 'formtext',
        hideForAttribute: 'always'
      },
      {
        text: 'Paragraph field',
        description: 'Multi line text field',
        icon: 'formmulti_line',
        alias: 'formparagraph input',
        pro: true,
        invalidInAttribute: true,
        action: ({ dialog }) => dialog('FORMPARAGRAPH'),
        id: 'formparagraph',
        hideForAttribute: 'always'
      },
      {
        text: 'Dropdown menu',
        description: 'Options in a menu',
        pro: true,
        alias: 'formmenu input',
        invalidInAttribute: true,
        action: ({ dialog }) => dialog('FORMMENU'),
        id: 'formmenu',
        icon: 'formmenu',
        hideForAttribute: 'always'
      },
      {
        text: 'Toggle field',
        description: 'Toggle snippet sections',
        pro: true,
        alias: 'formtoggle input',
        invalidInAttribute: true,
        action: ({ dialog }) => dialog('FORMTOGGLE'),
        id: 'formtoggle',
        icon: 'formtoggle_start',
        hideForAttribute: 'always'
      },
      {
        text: 'Date/Time field',
        description: 'Date/Time chooser',
        pro: true,
        alias: 'formdate formtime input',
        invalidInAttribute: true,
        action: ({ dialog }) => dialog('FORMDATE'),
        id: 'formdate',
        icon: 'formdate',
        hideForAttribute: 'always'
      }
    ]
  }, {
    title: 'Autopilot',
    children: [
      {
        text: 'Click element',
        description: 'Click currently selected element', // '. If selector is supplied, then click on the element matching the selector.',
        action: ({ insert }) => insert('{click}'),
        id: 'click',
        icon: 'click',
        alias: 'click mouse',
        platforms: {
          browser: 'Click is fully supported on our Chrome extension'
        },
        hideForAttribute: 'always'
      },
      {
        text: 'Key press',
        description: 'Simulate keyboard presses',
        icon: 'key',
        alias: 'key enter escape tab',
        id: 'key',
        children: getKeyCommands(),
        hideForAttribute: 'always'
      },
      {
        text: 'Wait',
        description: 'Pause snippet execution',
        action: ({ dialog }) => dialog('WAIT'),
        id: 'wait',
        icon: 'wait',
        alias: 'wait delay pause',
        hideForAttribute: 'always'
      }
    ]
  }, {
    title: 'Dynamic logic',
    children: [
      {
        text: 'Formula',
        description: 'Dynamic calculation',
        pro: true,
        alias: 'formula equation calculate math =',
        action: ({ dialog }) => dialog('='),
        id: '=',
        icon: 'calc_icon',
        hideDuringAdd: true
      }, {
        text: 'If/Else condition',
        description: 'Hide/show contents',
        pro: true,
        invalidInAttribute: true,
        alias: 'if else conditional',
        action: ({ dialog }) => {
          return dialog('IF');
        },
        id: 'if',
        icon: 'if_icon',
        hideForAttribute: 'always'
      },
      {
        text: 'Repeat',
        description: 'Repeat multiple times',
        pro: true,
        alias: 'repeat loop',
        invalidInAttribute: true,
        action: ({ dialog }) => dialog('REPEAT'),
        id: 'repeat',
        icon: 'repeat_icon',
        hideForAttribute: 'always'
      }, {
        text: 'Error Message',
        description: 'Show an error message',
        pro: false,
        invalidInAttribute: true,
        alias: 'block stop',
        action: ({ dialog }) => {
          return dialog('ERROR');
        },
        id: 'error',
        icon: 'alert',
        hideForAttribute: 'always'
      }
    ]
  },
  {
    title: 'Data Blaze',
    children: [
      genDBDynamicItem('select', 'Read from table', 'Use data from a table'),
      genDBDynamicItem('insert', 'Insert row into table', 'Add a new row to a table'),
      genDBDynamicItem('update', 'Update table row', 'Change a row in a table'),
      genDBDynamicItem('delete', 'Delete table row', 'Remove a row from a table'),
    ]
  },
  {
    title: 'Miscellaneous',
    id: 'misc',
    children: [
      {
        text: 'Import ' + snippetOrPrompt,
        description: 'Embed another ' + snippetOrPrompt,
        alias: 'import embed alias',
        action: ({ dialog }) => dialog('IMPORT'),
        id: 'import',
        icon: 'import',
        noPage: true,
        hideDuringAdd: true
      },
      {
        text: 'Note',
        alias: 'note hide info',
        description: 'Note that is not inserted',
        invalidInAttribute: true,
        action: ({ dialog }) => dialog('NOTE'),
        id: 'note',
        icon: 'notenote_start',
        hideForAttribute: 'always'
      },
      {
        text: 'Website',
        description: 'Insert website information',
        icon: 'site',
        id: 'site',
        alias: 'extract html text site',
        platforms: {
          browser: true
        },
        action: ({ insert }) => insert('{site: text}'),
      }
    ]
  },
  {
    title: 'Remote commands',
    id: 'remote',
    onlyExactMatch: true,
    children: [
      {
        text: 'Load URL',
        alias: 'urlload',
        description: 'Load data from a URL',
        icon: 'remoteurl_load',
        id: 'urlload',
        hideForAttribute: 'simple',
        action: ({ dialog }) => dialog('URLLOAD'),
        hideDuringAdd: true
      },
      {
        text: 'Send URL',
        alias: 'urlsend',
        description: 'Send data to a URL',
        icon: 'remoteurl_ping',
        id: 'urlsend',
        hideForAttribute: 'simple',
        action: ({ dialog }) => dialog('URLSEND'),
        hideDuringAdd: true
      },
      {
        text: 'Dynamic image',
        alias: 'image',
        description: 'Inserts an image into the snippet. The image\'s URL can contain dynamic form values.',
        icon: 'image',
        id: 'image',
        hideForAttribute: 'always',
        action: ({ dialog }) => dialog('IMAGE'),
      },
    ],
  },
  {
    title: 'Code blocks',
    id: 'codeblock',
    onlyExactMatch: true,
    children: [
      {
        text: 'Run',
        alias: 'code',
        description: 'Triggers a code block exactly once, when this command is displayed',
        icon: 'run',
        id: 'run',
        hideForAttribute: 'always',
        action: ({ dialog }) => dialog('RUN'),
      },
      {
        text: 'Button',
        alias: 'button',
        description: 'Trigger a code block each time the button is pressed',
        icon: 'button',
        id: 'button',
        hideForAttribute: 'always',
        action: ({ dialog }) => dialog('BUTTON'),
      }
    ],
  }
];



function ActionsToolbar(props) {
  return <div style={{
    display: 'flex',
    border: 'solid 1px #ccc',
    margin: '12px 12px 12px',
    borderRadius: 8,
    backgroundColor: 'white',
    padding: 4
  }}>
    {props.children}
  </div>;
}


/**
 * 
 * @param {object} props
 * @param {boolean} props.preview
 * @param {string} props.snippetId
 * @param {string} props.groupId
 * @param {function} props.insert
 * @param {function} props.insertTable
 * @param {function} props.dialog
 * @param {function=} props.onChange
 * @param {Function=} props.onVariableNameChange
 * @param {boolean} props.xSharingDisabled
 * @param {boolean} props.userAddonsEnabled
 * @param {boolean} props.isAssociatedToUs
 * @param {boolean} props.isPage
 * @param {boolean} props.isAddon
 * @param {boolean} props.quickentry
 * @param {boolean} props.isAI
 * @param {function} props.getDelta
 * @param {Object<string, ActiveAddonType>=} props.addons
 * @param {boolean} props.showDynamicBadge
 * @param {boolean} props.showAddonHighlight
 * @param {boolean} props.isEditingToken
 * @param {boolean} props.isEditingAttribute
 * @param {object} props.activeTypes
 * @param {boolean=} props.override
 * @param {boolean=} props.showCommands
 * @param {function=} props.setShowCommands
 * @param {boolean=} props.readonly
 * @param {boolean=} props.inAttribute
 * @param {boolean=} props.duringAdd
 * @param {((groupData: GroupObjectType) => any)} props.onAddonClick
 * @param {boolean=} props.hideCommandsListToolbar
 */
const CommandsList = (props) => {
  const {
    onAddonClick,
    ...otherProps
  } = props;
  const {
    attributeFocused,
    insertIntoAttribute,
    attribute,
    inCommandsPanel
  } = useContext(SnippetEditorContext);
  const newCallbacks = {
    onAddonClick
  };
  const callbacks = useRef(newCallbacks);
  callbacks.current = newCallbacks;
  /**
   * @type {newCallbacks}
   */
  let callBacksState = { ...newCallbacks };
  for (const callbackName in callBacksState) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    callBacksState[callbackName] = useCallback((...args) => {
      return callbacks.current[callbackName](...args);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  }
  return <CommandsListInnerMemoized
    {...otherProps}
    {...callBacksState}
    attributeFocused={attributeFocused}
    inCommandsPanel={inCommandsPanel}
    insertIntoAttribute={insertIntoAttribute}
    attributeType={attribute?.type}
  />;
};
export default CommandsList;

/**
 * @typedef {object} CommandsListContextProps
 * @property {boolean} attributeFocused
 * @property {import('../Snippet/SnippetWrapper').SnippetWrapperContext['insertIntoAttribute']} insertIntoAttribute
 * @property {import('../Snippet/SnippetWrapper').SnippetWrapperContext['attribute']['type']} attributeType
 * @property {boolean} inCommandsPanel
 * 
 * @param {Parameters<CommandsList>['0'] & CommandsListContextProps} props 
 * @returns 
 */
function CommandsListInner(props) {
  const {
    attributeFocused,
    insertIntoAttribute,
    attributeType,
    inCommandsPanel
  } = props;

  const alsoShowCommands = (attributeFocused || inCommandsPanel);

  let [search, setSearch] = useState('');
  const dynamicCommandsRef = useRef(null);

  let dispatch = useDispatch();
  const { push: navigate } = useHistory();

  let hasRecentlyInstalledAddons = useTypedSelector(store => !!(store.uiState && store.uiState.recentlyInstalledAddons && store.uiState.recentlyInstalledAddons.size > 0));

  useEffect(() => {
    if (hasRecentlyInstalledAddons) {
      let commandPacks = document.querySelector('.command-pack-section');
      if (commandPacks) {
        commandPacks.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
      }
      dispatch({
        type: 'CLEAR_RECENT_ADDONS'
      });
    }
    // eslint-disable-next-line
  }, [hasRecentlyInstalledAddons]);

  let memberFields = useTypedSelectorDeepEquals(store => {
    if (!checkOrg(store)) {
      return null;
    }
    return ['email'].concat(((store.orgState && store.orgState.org && store.orgState.org.member_fields) || []).map(f => f.name));
  });

  useHotkey('cmd+shift+k, ctrl+shift+k', (e) => {
    dynamicCommandsRef.current?.focus();
  });

  if (memberFields) {
    let misc = SECTIONS.find(x => x.id === 'misc');
    // @ts-ignore
    let user = misc.children.find(x => x.id === 'user');
    if (!user) {
      user = {
        text: 'User properties',
        id: 'user',
        alias: 'user data user fields',
        description: 'User specific properties',
        children: [],
        icon: 'user'
      };
      misc.children.push(user);
    }
    user.children = [];
    for (let field of memberFields) {
      user.children.push({
        text: 'User ' + field,
        action: ({ insert }) => insert('{user: ' + field + '}'),
        id: 'user',
        component: field === 'email' && <IconButton
          size="small"
          style={{
            marginLeft: 12
          }}
          onClick={(e) => {
            // If we don't stop this event, then it also inserts
            // the {user:email} command itself into the snippet,
            // which is unexpected
            e.stopPropagation();
            e.preventDefault();

            // Open help page
            let win = window.open('https://blaze.today/commands/user/', '_blank');
            // We are denying this action in the desktop app and opening the link manually in the default browser.
            if (!isElectronApp()) {
              win.focus();
            }
          }}
        ><HelpIcon fontSize="small" /></IconButton>
      });
    }
  }

  let insert = (txt) => {
    setSearch('');
    if (alsoShowCommands && insertIntoAttribute) {
      return insertIntoAttribute(txt);
    }
    props.insert(txt);
  };
  let insertTable = (data) => props.insertTable(data);

  let onChange = (...items) => props.onChange?.(...items);

  const dialog = (command, defaults = {}) => {
    let commandObj = COMMANDS[command];
    let commandKey = command;
    const {
      items,
      fields,
      ...dialogConfig
    } = dialogConfigs[commandKey];
    let attributes = commandAttributes(commandObj, items);
    attributes.defaults = { ...attributes.defaults, ...defaults };
    const allFields = [
      ...attributes.fields,
      ...(fields || [])
    ];
    let useFields;
    const commandName = dialogConfig.commandName;
    if (
      (commandName === 'dbselect' || commandName === 'urlload')
      && inCommandsPanel
      && attributeType === 'block'
    ) {
      /** @type {string[]} */
      const invalids = CODE_BLOCK_INVALID_ATTRS[commandName];
      // Do not show menu/name properties in the dialog when we're
      // inside a block context
      useFields = allFields.filter(x => !invalids.includes(x.attribute.name));
    } else {
      useFields = allFields;
    }
    const useDefaults = {
      ...dialogConfig.defaults,
      ...attributes.defaults,
    };


    if (['urlsend', 'dbinsert', 'dbupdate', 'dbdelete'].includes(commandName) && attributeType === 'block') {
      useFields.push({
        'id': 'instant',
        'clearUntouched': true,
        'attribute': {
          'required': true,
          'description': 'Command runs immediately. Required in code block.',
          'type': 'boolean',
          'name': 'instant',
        },
        // User cannot change it
        disabled: true,
      });
      // Enable the instant
      useDefaults.instant = true;
    }
    const config = {
      ...dialogConfig,
      ...attributes,
      fields: useFields,
      defaults: useDefaults,
    };
    return props.dialog(config).then(data => {
      insert(field(commandKey.toLowerCase(), data));
      return data;
    });
  };

  let actions = {
    onChange,
    insert,
    insertTable,
    dialog,
    onVariableNameChange: props.onVariableNameChange
  };


  /** @type {SectionType} */
  let addons = null;
  let items = [];

  if (props.addons && Object.keys(props.addons).length) {
    /** @type {Set<import('../../Sync/Sync').Group>} */
    let addonGroups = new Set();
    for (let addon in props.addons) {
      addonGroups.add(props.addons[addon].addon.group);
    }
    for (let group of addonGroups) {
      let groupData = group.data;
      let imageUrl = groupData.options?.addon?.icon_image_url;
      const supportedPlatforms = getPackSupportedPlatforms(group);

      const item = {
          id: groupData.id,
          icon: () => imageUrl ? <img
            src={imageUrl}
            alt="Command pack icon"
            style={{
              width: 26,
              height: 26,
              borderRadius: 100,
              verticalAlign: 'middle',
              border: 'solid 1px #b3b3b3',
              marginRight: 4,
              padding: 2
            }}
          /> : <AddonIcon style={{
            verticalAlign: 'middle',
            opacity: 0.7
          }} />,
          text: groupData.name,
          description: <span style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: 'calc(100% - 36px)',
            display: 'block'
          }}>{groupData.info}</span>,
          alias: groupData.info,
          children: [],
          platforms: supportedPlatforms,
          editConfig: <span style={{
            opacity: .7
          }}>
            {groupData.options.addon.config && groupData.options.addon.config.content && <IconButton
              size="small"
              style={{
                marginLeft: 12
              }}
              onClick={() => {
                props.onAddonClick(groupData);
              }}
            ><ConfigureIcon fontSize="small" /></IconButton>}
            {groupData.associated_addon_id && <IconButton
              size="small"
              style={{
                marginLeft: 12
              }}
              component={Link}
              to={'/packs/' + groupData.associated_addon_id}
            >
              <HelpIcon fontSize="small" />
            </IconButton>}
          </span>
        },
        itemChildren = item.children;

      let processAttribute = (attr, positional, defaults) => {
        attr = Object.assign({}, attr);

        let id = !positional ? attr.name : '%positional_' + positional;
        if (attr.type === 'boolean') {
          defaults[id] = (typeof attr.default === 'string') ? attr.default === 'yes' : attr.default;
        }
        if (positional) {
          attr.required = true;
        }
        return {
          id,
          attribute: attr,
          clearUntouched: true
        };
      };

      let addons = group.snippets.slice();
      addons.sort((a, b) => a.data.order - b.data.order);
      for (let addon of addons) {
        if (!aiBlazeCommandsFilter(addon.addonOptions.commands)) {
          continue;
        }
        let defaults = {};
        let addonOptions = addon.data.options.addon;

        if (!addonOptions) {
          addonOptions = /** @type {any} */ ({});
        }

        if (!addon.addonOptions) {
          // when dragging a snippet to an addon, may not be defined
          // at first
          continue;
        }
        let handleInsert;
        let positional = (addonOptions.positional || []);
        let named = (addonOptions.named || []);
        let command = addon.addonOptions.command;

        /**
         * @param {string} value 
         */
        function addonInsertFn(value) {
          insert(value);
          if (addon.addonOptions?.connected) {
            updateGroupAddonPermissions(props.groupId, command);
          }
        }
        let needsDialog = positional.length !== 0 || named.length !== 0;
        if (props.duringAdd && needsDialog) {
          continue;
        }
        if (!needsDialog) {
          handleInsert = () => addonInsertFn('{' + command + '}');
        } else {
          let fields = positional.map((a, i) => processAttribute(a, i + 1, defaults)).concat(named.filter(a => a.visibility !== 'hidden').map(a => processAttribute(a, false, defaults)));

          handleInsert = () => props.dialog({
            title: addon.data.name,
            footer: addonOptions.description ? <T color="textSecondary" style={{ marginTop: 12, maxHeight: 120, overflowY: 'auto', whiteSpace: 'pre-wrap' }}>{addonOptions.description}</T> : '',
            defaults,
            fields
          }).then((data) => {
            for (let d of data) {
              if (d.id.startsWith('%positional_')) {
                delete d.id;
              }
            }
            addonInsertFn(field(command, data));
          });
        }

        itemChildren.push({
          text: addon.data.name,
          action: handleInsert,
          id: 'addon-' + group.id + '-' + addon.id,
          invalidInAttribute: addon.addonOptions.invalidInAttribute,
          description: <span style={{ display: 'block', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
            {addonOptions.description}
          </span>,
          isAddon: true
        });
      }
      if (!itemChildren.length) {
        continue;
      }

      itemChildren.push('-');
      itemChildren.push({
        text: 'About command pack...',
        action: () => {
          navigate('/packs/' + groupData.associated_addon_id);
        }
      });
      items.push(item);
    }
  }

  if (props.userAddonsEnabled) {
    if (items.length) {
      items.push({
        text: 'Get more command packs',
        description: 'Extend ' + fullAppName,
        alias: 'plugins extensions',
        id: 'get-more-packs',
        icon: () => <AddonIcon />,
        action: () => navigate('/packs')
      });
    } else {
      items.push({
        text: 'Get command packs',
        description: 'Extend ' + fullAppName,
        alias: 'plugins extensions',
        id: 'get-packs',
        pro: true,
        icon: () => <AddonIcon />,
        action: () => navigate('/packs')
      });
    }
  }


  if (items.length && (!props.override || alsoShowCommands)) {
    addons = {
      title: 'Command packs',
      children: items,
      id: 'commands'
    };
  }

  let toolbar = (isAttribute) => !props.hideCommandsListToolbar && props.snippetId && !isAttribute ? <ActionsToolbar>

    {/*TODO: enable this back once AI Snippets work on the community */}
    {(props.xSharingDisabled || props.isAI) ? null :
      <PublishButton
        style={{ marginLeft: 0 }}
        snippetId={props.snippetId}
        groupId={props.groupId}
        getDelta={props.getDelta}
      />}
    {props.isAssociatedToUs && <ChangeHistoryButton
      tooltip="Snippet content history"
      title="Snippet content history"
      type="snippet"
      groupId={props.groupId}
      snippetId={props.snippetId}
    />}
    <CommentsButton entityType="snippet" entityId={props.snippetId} />
    <div style={{ flex: 1 }}/>
    <IconButton
      style={{ marginLeft: 0 }}
      onClick={() => {
        showTrashConfirmation({
          item: 'this snippet',
          onDelete: () => {
            return deleteSnippet(props.snippetId, props.groupId, navigate);
          }
        });
      }}
    >
      <DeleteOutlineIcon />
    </IconButton>
  </ActionsToolbar> : null;

  let displayedSections = [];
  let displayedSectionsForAttribute = [];

  let usage = getUsage();
  let serializedUsageForMemoComparison = JSON.stringify(usage);

  // Show at most four of the most used commands (used at least 3 times)
  // Consider recency + frequency.  This improves results by showing heavily used commands and also recently used ones
  const frequentSectionsIds = useMemo(() => {
    /**
     * @type {Map<string, {score: number, usage: number}>}
     */
    const scores = new Map();
    usage.forEach((item, index) => {
      scores.set(
        item,
        {
          // score = how often is used (recency) + how many times (frequency)
          score: (usage.length - index) + scores.get(item)?.score || 0,
          usage: (scores.get(item)?.usage || 0) + 1
        }
      );
    });


    return  Array.from(scores)
      .filter(score => score[1].usage >= 3)
      .sort((a, b) => b[1].score - a[1].score)
      .slice(0, 4)
      .map(score => score[0]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serializedUsageForMemoComparison]);

  let frequent = {
    title: 'Frequently used',
    children: [],
    id: 'frequent',
  };
  let frequentForAttribute = { ...frequent, children: [] };
  if (frequentSectionsIds.length) {
    displayedSections.push(frequent);
    displayedSectionsForAttribute.push(frequentForAttribute);
  }

  // beware this is an object reference
  let potentialSections = SECTIONS;

  if (isAiBlaze) {
    const potentialSectionsForAiBlaze = [];

    // only leave allowed commands for AI Blaze
    potentialSections.forEach((potentialSection, index) => {
      // clear children to assign only AI Blaze commands below
      const newSection = { ...potentialSection, children: [] };

      potentialSection.children.forEach((section) => {
        const isCommandAllowedInAiBlaze = !!COMMANDS[section.id.toUpperCase()]?.allowedInAiBlaze;

        if (isCommandAllowedInAiBlaze) {
          newSection.children.push({ ...section });
        }
      });

      if (newSection.children.length) {
        potentialSectionsForAiBlaze.push(newSection);
      }
    });

    potentialSections = potentialSectionsForAiBlaze;
  } else if (props.isAI) {
    potentialSections = potentialSections.slice();
    potentialSections.splice(potentialSections.findIndex(item => item.title === 'Autopilot'), 1);

    const sectionRemoveMappings = {
      'Basic commands': ['cursor'],
      'Data Blaze': ['dbdelete', 'dbinsert', 'dbupdate']
    };

    for (const sectionName in sectionRemoveMappings) {
      const idsToRemove = sectionRemoveMappings[sectionName];
      let sectionIndex = potentialSections.findIndex(item => item.title === sectionName);
      potentialSections[sectionIndex] = Object.assign({}, potentialSections[sectionIndex]);
      potentialSections[sectionIndex].children = potentialSections[sectionIndex].children.slice();

      let index;
      do {
        const items = potentialSections[sectionIndex].children;
        index = items.findIndex(item => idsToRemove.includes(item.id)); 
        if (index !== -1) {
          potentialSections[sectionIndex].children.splice(index, 1);
        }
      } while (index !== -1);
    }
  }
  if (!props.isPage && !props.isAddon && addons) {
    potentialSections = potentialSections.concat(addons);
  }

  const isBlockAttribute = attributeType === 'block';
  const typesToHide = ['always'].concat(isBlockAttribute ? [] : ['simple']);
  for (let section of potentialSections) {
    if (section.onlyExactMatch) {
      if (!section.children.some(x => x.id === search)) {
        continue;
      }
    }
    let newSection = Object.assign({}, section);
  
    newSection.children = newSection.children.slice();
    let newSectionForAttribute = Object.assign({}, newSection);
    newSectionForAttribute.children = newSectionForAttribute.children.filter(s => !typesToHide.includes(s.hideForAttribute) && (!props.duringAdd || !s.hideDuringAdd));
    for (let i = 0; i < newSection.children.length; i++) {
      let child = newSection.children[i];
      if (child.id && frequentSectionsIds.includes(child.id)) {
        frequent.children.push(child);
        if (!typesToHide.includes(child.hideForAttribute)
          && (!props.duringAdd || !child.hideDuringAdd)
        ) {
          frequentForAttribute.children.push(child);
        };
      }
    }
    if (newSection.id === 'forms') {
      displayedSectionsForAttribute.splice(0, 0, newSectionForAttribute);
      if (['formula', 'iterator', 'block'].includes(attributeType)) {
        newSectionForAttribute.children = newSectionForAttribute.children.concat(FUNCTIONS_SECTIONS);
      } else if (attributeType === 'constant') {
        newSectionForAttribute.constant = true;
      }
    } else if (newSection.children.length) {
      displayedSectionsForAttribute.push(newSectionForAttribute);
    }
    if (newSection.children.length) {
      displayedSections.push(newSection);
    }
  }
  return <Box
    sx={[{
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      flex: 1
    }]}
    id={props.inAttribute ? 'sidePanel-extra' : 'sidePanel'}
  >
    {!props.duringAdd && <Box
      sx={{
        mx: 2
      }}
    >

      <TextField
        inputRef={dynamicCommandsRef}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        variant="outlined"
        size="small"
        type="search"
        placeholder="Dynamic commands"
        fullWidth
        margin="dense"
        
        sx={{
          mb: 1,
          '.MuiInputBase-root': {
            p: 0.5
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{
              pointerEvents: 'none'
            }}>
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: ( 
            <InputAdornment position="end">
              <Tooltip title={`Dynamic commands allow you to include the current date, form fields or more in your ${snippetOrPrompt}s. Click here to learn more.`}>
                <IconButton
                  tabIndex={-1}
                  size="small"
                  onClick={() => {
                    window.open('https://blaze.today/guides/dynamic-commands/', '_blank');
                  }}
                >
                  <HelpIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    </Box>}
    <List
      sx={[{
        width: '100%',
        bgcolor: 'background.paper',
        position: 'relative',
        overflowY: 'auto',
        '& ul': { padding: 0 },
        flex: 1
      }]}
      subheader={<li />}
    >
      {(props.inAttribute ? displayedSectionsForAttribute : displayedSections).map((section, i) => <InsertionSectionMemoized
        key={i}
        {...section}
        isPage={props.isPage}
        search={search}
        actions={actions}
        quickentry={props.quickentry}
        names={props?.activeTypes ? Object.keys(props.activeTypes) : []}
        types={props?.activeTypes}
        plainInsert={props.inAttribute}
        inAttribute={props.inAttribute}
        isAiBlaze={isAiBlaze}
        isAI={props.isAI}
      />)}  

    </List>

    {toolbar(props.inAttribute)}
  </Box>;
}

const CommandsListInnerMemoized =  React.memo(CommandsListInner, (props1, props2) => {

  const {
    addons: addons1,
    activeTypes: activeTypes1 = null,
    ...otherProps1
  } = props1;

  const {
    addons: addons2,
    activeTypes: activeTypes2 = null,
    ...otherProps2
  } = props2;
  if (
    (!addons1 || !addons2)
    && addons1 !== addons2) {
    return false;
  } else if (
    addons1 && addons2
    && !equalsES6(Object.keys(addons1), Object.keys(addons2))) {
    return false;
  }
  if (
    (!activeTypes1 || !activeTypes2)
    && activeTypes1 !== activeTypes2) {
    return false;
  } else if (
    activeTypes1 && activeTypes2 
    && !equalsES6(Object.keys(activeTypes1), Object.keys(activeTypes2))) {
    return false;
  }

  return equalsES6(otherProps1, otherProps2);
});


/**
 * 
 * @typedef InsertionSectionProps 
 * @property {string} title
 * @property {boolean=} isPage
 * @property {string=} search
 * @property {object} actions
 * @property {(...items: any[]) => any} actions.onChange
 * @property {(txt: any) => any} actions.insert
 * @property {(data: any) => any} actions.insertTable
 * @property {(command: any) => any} actions.dialog
 * @property {boolean=} quickentry
 * @property {string[]=} names
 * @property {import('./SnippetEditor').EditorContextType['activeTypes']=} types
 * @property {boolean=} plainInsert
 * @property {boolean=} inAttribute
 * @property {boolean=} isAiBlaze
 * @property {boolean=} isAI
 *
 * 
 * @param {SectionType & InsertionSectionProps} props
 */
function InsertionSection(props) {
  let [showFormConfigureEl, setShowFormConfigureEl] = useState(null);

  let children = props.children;

  let names = [];
  let typedNames = {};
  if (props.id === 'forms') {
    names = props.names || [];
  }

  if (props.search.trim()) {
    let search = props.search.toLowerCase();
    let inTitle = props.title.toLowerCase().includes(search);

    // When searching for a frequently used command we don't show frequently used section.
    // On the other hand if a user searches for frequently used title we display the section.
    if (!inTitle && props.id === 'frequent') {
      return null;
    }
    const fullChildren = children;
    children = [];
    for (let index = 0; index < fullChildren.length; index++) {
      const child = fullChildren[index];
      if (
        inTitle
        || child.text.toLowerCase().includes(search)
        || child.alias?.includes(search)
      ) {
        children.push(child);
        continue;
      }
      if (!child.children) {
        continue;
      }
      
      // find in child items
      const subChilds = child.children.filter(c => (
        c.plain_text?.includes(search)
        || c.alias?.includes(search)
        // command pack's command name
        || (typeof c.text === 'string' && c.text.toLowerCase().includes(search))
      ));
      
      if (subChilds.length > 3) {
        children.push(child);
      } else if (subChilds.length >= 1) {
        for (let startIndex = 0; startIndex < subChilds.length; startIndex++) {
          const subChild = subChilds[startIndex];
          children.push({
            icon: child.icon,
            ...subChild
          });
          
        }
      }
    }

    names = names.filter(x => x.toLowerCase().includes(search));
  }

  for (let name of names) {
    typedNames[name] = props.types ? props.types[name] : null;
  }

  
  if (props.isPage) {
    children = children.filter(x => !x.noPage);
  }

  if (!children.length && !names.length) {
    return null;
  }

  return <>
    <li key={`section-${props.title}`} className={props.id === 'commands' ? 'command-pack-section' : ''} data-testid={props.id === 'commands' ? ('sidebar-section-' + props.id) : undefined}>
      <ul>
        <ListSubheader
          sx={[{
            display: 'flex',
            alignItems: 'center'
          }]}>
          <div style={{
            flex: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>{props.inAttribute && props.title2 ? props.title2 : props.title}</div>
          {props.title === 'Data Blaze' ? <div style={{
            display: 'block',
            border: 'solid 1px #bbb',
            padding: '2px 6px',
            borderRadius: 6,
            lineHeight: '18px',
            opacity: .9
          }}>Beta</div> : null}
          {!props.isAI && props.id === 'forms' && !props.inAttribute ? <IconButton
            size="small"
            onClick={(e) => {
              setShowFormConfigureEl(e.target);
            }}
          >
            <ConfigureIcon fontSize="small" />
          </IconButton> : null}
        </ListSubheader>
        {props.constant && (
          <ListItem>
            <Tooltip title="Form values cannot be inserted here">
              <span><IconButton
                size="small"
                disabled
              >
                <ListAltIcon />
              </IconButton></span>
            </Tooltip>
          </ListItem>
        )}
        {!props.constant && !!names.length && <ListItem>
          <ListItemIcon><FunctionsIcon /></ListItemIcon>
          <ListItemText
            sx={{
              maxWidth: '100%'
            }}
            primary={<Box
              sx={{
                lineHeight: '1.8em',
              }}
            >{names.map(name => <NameChip
                key={name}
                name={name}
                type={typedNames[name]} 
                actions={props.actions}
                plainInsert={props.plainInsert}
                inAttribute={props.inAttribute}
              />)}
            </Box>}
          />
        </ListItem>}

        {children.map((item, i) => (
          <InsertionItemMemoized key={i} {...item} actions={props.actions} isAiBlaze={props.isAiBlaze} />
        ))}
      </ul>
    </li>
    {showFormConfigureEl && <Menu
      anchorEl={showFormConfigureEl}
      open
      onClose={() => {
        setShowFormConfigureEl(null);
      }}
    >
      <ListItem>
        <FormControlLabel 
          label="Enable quick entry"
          control={
            <Checkbox
              checked={!!props.quickentry}
              sx={{ 
                padding: '4px 8px'
              }}
              onChange={() => {
                props.actions.onChange('quickentry', !props.quickentry);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          } 
          labelPlacement="start"
          sx={{
            margin: 0,
          }}
        />
      </ListItem>
    </Menu>}
  </>;
}
const InsertionSectionMemoized = React.memo(InsertionSection);


const LOCALSTORAGE_USAGE_KEY = 'COMMAND_USAGE_FREQ';

function getUsage() {
  return JSON.parse(window.localStorage.getItem(LOCALSTORAGE_USAGE_KEY) || '[]');
}

function setUsage(usage) {
  window.localStorage.setItem(LOCALSTORAGE_USAGE_KEY,JSON.stringify(usage));
}

/**
 * 
 * @typedef InsertionItemProps
 * @property {Parameters<InsertionSection>[0]['actions']} actions 
 * @property {React.ReactNode=} props.editConfig
 * @property {boolean=} props.pro
 * @property {boolean=} props.isPage
 * @property {boolean=} props.isEditingToken
 * @property {boolean=} props.inCommandsPanel
 * @property {boolean=} props.isAiBlaze
 *
 * 
 * 
 * @param {SectionChildType & InsertionItemProps} props 
 * @returns 
 */
function InsertionItem(props) {
  let [anchorEl, setAnchorEl] = useState(null);
  let [transitionDuration, setTransitionDuration] = useState(undefined);
  let [closeFn, setCloseFn] = useState(null);

  function logUsage() {
    if (props.id) {
      let usage = getUsage();
      usage.unshift(props.id);
      // base results on the 100 most recent selections
      setUsage(usage.slice(0, 100));
    }
  }

  /** @type {any} */
  let action = () => {
    logUsage();

    props.action(props.actions);
  };
  if (props.children && props.children.length) {
    action = (e) => {
      setAnchorEl(e.target);
    };
  }

  /**
   * @type import('react').CSSProperties
   */
  const wrapAddonsName = props.isAddon
    ? { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }
    : {};

  const listItem = <ListItem
    secondaryAction={props.editConfig}
    disablePadding
    sx={{
    }}
  >
    <ListItemButton
      data-testid={props.testId}
      onClick={(e) => {
        action(e);
      }}
      dense
      sx={{
        minHeight: 50
      }}
    >
      <ListItemIcon><div style={{ zoom: 1.3, display: 'flex' }}>{typeof props.icon === 'string' ? ICON_MAPPING[props.icon] : props.icon()}</div></ListItemIcon>
      <ListItemText primary={
        <div style={{ display: 'flex', alignContent: 'center' }}><div style={{ flex: 1, maxWidth: '100%', ...wrapAddonsName }}>{props.text}</div>
          {(props.pro && !props.isPage && !props.isAiBlaze) ? <ProChip zoom={0.7} style={{ marginLeft: 8 }}/> : null}
          {(props.platforms && !props.isPage && !props.platforms[CURRENT_PLATFORM] && props.platforms.browser) ? <ChromeChip title={typeof props.platforms.browser === 'string' ? props.platforms.browser : null} /> : null}
        </div>} secondary={props.description} />
    </ListItemButton>
  </ListItem>;
  return <>
    {listItem}
    {
      props.children && !!props.children.length && <Menu
        transitionDuration={transitionDuration}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        TransitionProps={{
          onEntered: () => {
            // Should close immediately
            setTransitionDuration(0);
          },

          onExited: () => {
            if (closeFn) {
              closeFn(props.actions);
              setCloseFn(null);
            }
            setTransitionDuration(undefined);
          }
        }}>
        {props.children.map((item, i) => {
          if (item === '-') {
            return <Divider key={i} />;
          } else {
            return <MenuItem
              key={i}
              disabled={props.isEditingToken && item.invalidInAttribute}
              onClick={() => {
                if (item.onCheck) {
                  item.onCheck(!item.checked(), props.actions);
                } else {
                  logUsage();
                  // insertion happens after close, otherwise focus can be
                  // messed up
                  setCloseFn(() => item.action);
                  setAnchorEl(null);
                }
              }}>
              <ListItemText
                sx={{
                  maxWidth: 300
                }}
                primary={
                  <div style={{
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    justifyContent: 'space-between',
                    alignItems: 'top',
                    width: '100%'
                  }}>
                    <div style={{
                      flex: 1,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: item.icon ? 'flex' : undefined,
                      alignItems: 'center'
                    }}>
                      {item.icon && <div className="embedded-icon-menu">{ICON_MAPPING[item.icon]}</div>}
                      {item.text}
                    </div>
                    <div>
                      {(item.pro && !props.isPage) ? <ProChip zoom={0.85} style={{ marginLeft: 8 }}/> : null}
                      {(item.beta && !props.isPage) ? <BetaChip zoom={0.85} style={{ marginLeft: 8 }}/> : null}
                      {item.component ? item.component : null}
                    </div>
                  </div>
                }
                primaryTypographyProps={{
                  component: 'div'
                }}
                secondary={item.description}
                secondaryTypographyProps={{
                  sx: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }
                }}
              />
            </MenuItem>;
          }
        })}
      </Menu>
    }

  </>;
}

const InsertionItemMemoized = React.memo(InsertionItem);