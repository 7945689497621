import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import { dialog } from '../DataDialog/DataDialog';
import Feedback from '../Feedback/Feedback';
import InnerMain from './InnerMain';
import AuthDialog from '../Auth/AuthDialog';
import { fontStyle } from '../SnippetEditor/fontConfig';
import { Helmet } from 'react-helmet-async';
import DesktopNavigation from './DesktopNavigation';
import { sendMessageToClient } from '../../desktop_utilities';
import CommonDialogs from './CommonDialogs';
import useTokenCookie from '../../hooks/useTokenCookie';

function AiAppInner() {
  useTokenCookie();

  // We did it in the dashboard to avoid separate native code changes in Mac and Windows to detect text boxes.
  useEffect(() => {
    const handleFocusChange = (focus) => (event) => {
      const element = event.target;

      const isEditable = element.isContentEditable || element.tagName === 'INPUT' || element.tagName === 'TEXTAREA';

      if (isEditable) {
        sendMessageToClient({
          type: 'editable-focused',
          data: focus
        });
      }
    };

    const onFocusHandler = handleFocusChange(true);
    const onBlurHandler = handleFocusChange(false);

    document.addEventListener('focusin', onFocusHandler);
    document.addEventListener('focusout', onBlurHandler);

    return () => {
      document.removeEventListener('focusin', onFocusHandler);
      document.removeEventListener('focusout', onBlurHandler);
    };
  }, []);

  return (
    <>
      <Helmet defer={false}>
        <title>AI Blaze Dashboard</title>
      </Helmet>
      <style>{fontStyle}</style>
      <Switch>
        <Route component={InnerMain} />
      </Switch>

      <DesktopNavigation />
      <AuthDialog />
      <Feedback />
      <CommonDialogs />
      <ErrorBoundary dialog details="in the data dialog">{dialog}</ErrorBoundary>
    </>
  );
}


export default AiAppInner;
