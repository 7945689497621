import React from 'react';
import Alert from '@mui/material/Alert';
import T from '@mui/material/Typography';
import AlertTitle from '@mui/material/AlertTitle';
import { createGroup } from '../../data';
import AsyncButton from '../AsyncButton/AsyncButton';
import { useTypedSelectorShallowEquals } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { snippetOrPrompt } from '../../aiBlaze';


export default function CreateAddonPanel() {
  const { push: navigate } = useHistory();

  let { email, name } = useTypedSelectorShallowEquals((store)=> {
    return {
      email: store.userState.email,
      name: store.userState.firebaseUser.displayName || '',
    };
  });


  return <Alert severity="info" style={{
    marginTop: 36,
    marginBottom: 24
  }}>
    <AlertTitle>Want to create and share your own custom commands?</AlertTitle>
    <T>Commands are based on {snippetOrPrompt}s – so if you can create {snippetOrPrompt}s, you can create and share commands. Create a command pack folder to start creating your own commands. Once you are ready, you can submit them to be listed here.</T>
    <div style={{ textAlign: 'right', marginTop: 12 }}>
      <AsyncButton
        variant="outlined"
        onClick={(done) => {
          return createGroup({
            name: 'New Command Pack',
            options: {
              addon: {
                namespace: 'pack',
                author_name: name,
                author_email: email
              }
            }
          }).then(newGroupId => {
            navigate('/folder/' + newGroupId);
            done();
          }).catch(_err => {
            console.log(_err);
            done();
          });
        }}
      >
            Create command pack folder
      </AsyncButton>
    </div>
  </Alert>;
}