import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import T from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import SecurityIcon from '@mui/icons-material/Security';
import { iconForAttribute } from '../SnippetEditor/type_icons';
import Callout from '../Callout/Callout';
import AddonIcon from '@mui/icons-material/WidgetsOutlined';
import AsyncButton from '../AsyncButton/AsyncButton';
import { storage } from '../../utilities';
import { usersSettingsRef } from '@store';
import { toast } from '../../message';
import { deleteField } from 'firebase/firestore';
import { grantExpansion, isNonsensitiveGrant } from '../Version/limitations';
import { useDispatch } from 'react-redux';
import { log } from '../../logging/logging';
import { CURRENT_PLATFORM, getAddonInstallation } from '../../flags';
import { useTypedSelectorDeepEquals } from '../../hooks';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { makeRef } from '../../firebase_utilities';
import { getPackSupportedPlatforms } from './supported_platforms';
import { ChromeIcon } from '../Version/Assets';
import AddonInstaller from './AddonInstaller';
import AddonGrantList from './AddonGrantList';
import { confirmAddonGrants } from './addon_utilities';
import { aiBlazeCommandsFilter, snippetOrPrompt } from '../../aiBlaze';
import { fullAppName } from '../../raw_flags';


/**
 * @param {object} props
 * @param {string[]} props.allAddonsInstalled
 * @param {string[]} props.installedUserAddons
 * @param {string[]} props.installedOrgAddons
 * @param {string} props.orgId
 * @param {string} props.orgRole
 * @param {AddonObjectInnerType} props.addon
 * @param {GroupObjectType} props.group
 * @param {function} props.onBack
 * @param {boolean} props.userAddonsBlocked
 */
function AddonDetailsBase(props) {
  let installerRef = useRef(null);

  let dispatch = useDispatch();
  
  // whether we're installed at any level
  let { approvedGrants, installedBy } = useTypedSelectorDeepEquals((state) => getAddonInstallation(props.addon.id, state.userState, state.orgState));

  let needsUpgrade = (approvedGrants && !!grantExpansion(approvedGrants, props.addon.grants));

  /** @type {ReturnType<typeof getPackSupportedPlatforms>} */
  let supportedPlatforms;
  if (installedBy) {
    supportedPlatforms = getPackSupportedPlatforms(props.group);
  } else {
    // when addon is not installed, we read the grants to determine the platforms
    if (props.addon.grants?.site_hosts?.length > 0) {
      supportedPlatforms = { browser: true };
    }
  }
 
  function renderScopes() {
    const grantList = <AddonGrantList grants={props.addon.grants} />;

    return <Callout title="Command pack permissions" icon={<SecurityIcon />} style={{
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }}>
      <T variant="body1" paragraph>You should only activate command packs you trust. {isNonsensitiveGrant(props.addon.grants) ? 'This command pack does not have access to your data or the sites you use it on.' : `When used in a ${snippetOrPrompt}, this command pack:`}</T>
      {grantList}
    </Callout>;
  }


  function renderCommands() {
    let snippets = props.addon.data.snippets;
    // Sorts disabled snippets to the bottom
    snippets.sort((a, b) => {
      const aDisabled = !aiBlazeCommandsFilter(a.grants.commands);
      const bDisabled = !aiBlazeCommandsFilter(b.grants.commands);
      if (aDisabled && !bDisabled) {
        return 1;
      } else if (!aDisabled && bDisabled) {
        return -1;
      }
      return 0;
    });
    let docs = [];
    for (let snippet of snippets) {
      const disabled = !aiBlazeCommandsFilter(snippet.grants.commands);
      let positional = snippet.addon.positional || [];
      let named = snippet.addon.named || [];
      let example = '{' + props.addon.namespace + '-' + snippet.command;
      let first = true;
      let hasAttributes = positional.filter(a => a.visibility !== 'hidden').length || named.filter(a => a.visibility !== 'hidden').length;
      let attrs = '';
      for (let item of positional) {
        if (!first) {
          attrs += '; ';
        } else {
          first = false;
        }
        attrs += 'placeholder' in item ? item.placeholder : item.default;
      }
      for (let item of named) {
        if (item.required) {
          if (!first) {
            attrs += '; ';
          } else {
            first = false;
          }
          attrs += item.name + '=' + ('placeholder' in item ? item.placeholder : item.default);
        }
      }
      if (!!attrs) {
        example += ': ' + attrs;
      }
      example += '}';

      function icon(attr) {
        return <div style={{
          display: 'inline-block',
          width: 24,
          height: 24,
          marginRight: 8,
          verticalAlign: 'middle',
          opacity: .6
        }}>{iconForAttribute(attr)}</div>;
      }

      /**
       * @param {UserDefinedPropertyType} item 
       */
      function getPlaceholder(item) {
        let blank = () => {
          return <i style={{ opacity: .5 }}>Blank</i>;
        };

        if ('placeholder' in item) {
          if (item.placeholder === '') {
            return blank();
          }
          return item.placeholder;
        }
        if ('default' in item) {
          if (item.default === '') {
            return blank();
          }
          return item.default;
        }

        if (item.type === 'boolean') {
          return 'no';
        } else if (item.type === 'number') {
          return '0';
        }

        return blank();
      }


      let attributes = [];
      for (let item of positional) {
        attributes.push(<TableRow key="position">
          <TableCell style={{
            whiteSpace: 'nowrap'
          }}>{icon(item)} <i>positional</i></TableCell>
          <TableCell><b>Yes</b></TableCell>
          <TableCell>{item.description}</TableCell>
          <TableCell style={{ whiteSpace: 'pre-wrap' }}>{getPlaceholder(item)}</TableCell>
        </TableRow>);
      }

      for (let item of named) {
        if (item.visibility !== 'hidden') {
          attributes.push(<TableRow key={'named_' + item.name}>
            <TableCell style={{
              whiteSpace: 'nowrap'
            }}>{icon(item)} <b>{item.name}</b></TableCell>
            <TableCell>{item.required ? <b>Yes</b> : 'No'}</TableCell>
            <TableCell>{item.description}</TableCell>
            <TableCell>{getPlaceholder(item)}</TableCell>
          </TableRow>);
        }
      }

      docs.push(<Paper key={snippet.command} elevation={2} style={{ padding: 10, paddingRight: 20, marginBottom: 22,  opacity: disabled ? 0.5 : 1, pointerEvents: disabled ? 'none' : 'auto' }}>
        <T paragraph variant="h6">
          <AddonIcon style={{
            verticalAlign: 'text-bottom',
            marginRight: 10
          }} />{snippet.name}{disabled ? ` (unsupported in ${fullAppName})` : ''}
        </T>
        <div style={{ paddingLeft: 20 }}>
          <T paragraph style={{ whiteSpace: 'pre-wrap' }}>{snippet.addon.description}</T>

          <T paragraph color="textSecondary" variant="subtitle1">Example Usage</T>

          <div style={{
            fontFamily: 'monospace',
            marginBottom: 15,
            fontSize: 'large',
            whiteSpace: 'pre-wrap' /* Want to preserve new lines in examples */
          }}>{example}</div>

          {hasAttributes ? <>
            <T paragraph color="textSecondary" variant="subtitle1">Settings</T>
            <Paper elevation={0}>
              <Table style={{ paddingLeft: 20, marginBottom: 24 }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Setting</TableCell>
                    <TableCell>Required</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Example</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attributes}
                </TableBody>
              </Table>
            </Paper>
          </> : null}
        </div>
      </Paper>);
    }

    return <>
      <T paragraph variant="h5" style={{ textAlign: 'center', marginTop: 50, marginBottom: 40 }}>Included Replacement Commands</T>
      {docs}
    </>;
  }



  async function uninstall(onDone) {
    let id = props.addon.id;
    try {
      log({
        action: 'Remove Addon',
        label: {
          id,
          is_org: installedBy === 'organization'
        }
      });

      const messagePrefix = `The ${props.addon.data.name} command pack has been successfully removed`;

      if (installedBy === 'organization') {
        await storage.update(makeRef('orgs', props.orgId) , {
          ['addons.' + id]: deleteField()
        }, 'HIDE_AUTOSAVE');
        toast(`${messagePrefix} from your organization.`, {
          duration: 6000,
          intent: 'success'
        });
      } else {
        await storage.update(usersSettingsRef, {
          ['addons.' + id]: deleteField()
        }, 'HIDE_AUTOSAVE');
        toast(`${messagePrefix}.`, {
          duration: 6000,
          intent: 'success'
        });
      }

      dispatch({
        type: 'ADDON_UNINSTALLED',
        data: {
          name: props.addon.data.name
        }
      });

    } catch (e) {
      console.error(e);
      toast('An error occurred removing the command pack.', {
        duration: 6000,
        intent: 'danger'
      });
    }
    onDone();
  }

  /**
   * @param {'org'|'user'} type 
   * @param {function} done 
   */
  function initInstall(type, done) {
    installerRef.current.initInstall(type, done);
  }

  function actionButtons() {
    let buttons = [];

    if (installedBy !== 'organization' && props.orgId) {
      buttons.push(<AsyncButton
        key="add-org"
        onClick={(done) => {
          initInstall('org', done);
        }}
        style={{ marginRight: 12 }}
        variant="outlined"
        disabled={props.orgRole !== 'owner'}
      >Activate pack for your organization (admin only)</AsyncButton>);
    }

    if (!installedBy) {
      buttons.push(<AsyncButton
        key="add-user"
        onClick={(done) => {
          initInstall('user', done);
        }}
        style={{ marginRight: 12 }}
        color="primary"
        variant="contained"
      ><span>Activate pack</span>{props.orgId && <span>&nbsp;(for you only)</span>}</AsyncButton>);
    }

    if (installedBy === 'organization') {
      buttons.push(<AsyncButton
        key="remove-org"
        onClick={(done) => {
          uninstall(done);
        }}
        style={{ marginRight: 12 }}
        variant="outlined"
        disabled={ props.orgRole !== 'owner'}
      >Remove pack for your organization (admin only)</AsyncButton>);
    } else if (installedBy === 'user') {
      buttons.push(<AsyncButton
        key="remove-use"
        onClick={(done) => {
          uninstall(done);
        }}
        style={{ marginRight: 12 }}
        variant="outlined"
      ><span>Remove pack</span>{props.orgId && <span>&nbsp;(for you only)</span>}</AsyncButton>);
    }


    return buttons;
  }

  return (<>
    <AddonInstaller
      ref={installerRef}
      addon={props.addon}
    />
    <div>
      {needsUpgrade && <Alert
        severity="warning"
        style={{ marginBottom: 24 }}
        action={ <AsyncButton
          variant="contained"
          color="primary"
          disabled={installedBy === 'organization' && props.orgRole !== 'owner'}
          onClick={(done) => {
            confirmAddonGrants({
              grants: props.addon.grants,
              confirmButtonText: 'Approve',
              onConfirm: async () => {
                if (installedBy === 'organization') {
                  await storage.update(makeRef('orgs', props.orgId) , {
                    ['addons.' + props.addon.id + '.approved_grants']: props.addon.grants
                  }, 'HIDE_AUTOSAVE');
                } else {
                  await storage.update(usersSettingsRef, {
                    ['addons.' + props.addon.id + '.approved_grants']: props.addon.grants
                  }, 'HIDE_AUTOSAVE');
                }
                toast('Command pack permissions updated.', {
                  duration: 6000,
                  intent: 'success'
                });
                done();
              },
              onCancel: () => done()
            });
          }}
        ><span>Review and approve</span>{installedBy === 'organization' && <span>&nbsp;(admin only)</span>}</AsyncButton>}
      >
        <AlertTitle>New permissions require approval</AlertTitle>
        <T>This command pack requires you to approve new permissions to keep functioning</T>
      </Alert>}
      <div style={{
        height: 250
      }}>
        <img src={props.addon.data.banner_image_url || props.addon.data.image_url} alt="Pack Banner" style={{
          objectFit: 'cover',
          width: '100%',
          maxHeight: 250
        }}/>
      </div>
      <T gutterBottom color="textSecondary" style={{ marginBottom: 15 }}>
        Published by <a href={'mailto:' + props.addon.data.author_email} target="_blank" rel="noopener noreferrer">{props.addon.data.author_name}</a>
      </T>

      <T paragraph variant="body1" style={{ whiteSpace: 'pre-wrap' }}>{props.addon.data.description}</T>

      {supportedPlatforms && supportedPlatforms.browser && CURRENT_PLATFORM !== 'browser' && <Callout title="Not supported on this platform" icon={<ChromeIcon />} style={{
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '10px'
      }}><T variant="body1" paragraph>This addon is only supported on Chrome. To use this addon, install our Chrome extension.</T></Callout>}

      {renderScopes()}

      <div style= {{ paddingBottom: 60 }}>
        {renderCommands()}
      </div>
    </div>
    {!props.userAddonsBlocked && <div style={{
      padding: '12px 6px',
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      display: 'flex',
      backgroundColor: 'white',
      borderTop: 'solid 1px #ccc'
    }}>
      <Button
        onClick={() => {
          props.onBack();
        }}
        style={{ marginRight: 12 }}
        startIcon={<ChevronLeftIcon/>}
      >Back to list</Button>
      <div style={{ flex: 1 }}/>
      {actionButtons()}
    </div>}
  </>);
}


const AddonDetails = React.memo(AddonDetailsBase);
export default AddonDetails;
