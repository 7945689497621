import React, { useState, useEffect, useRef } from 'react';
import AddonCard from './AddonCard';
import AddonDetails from './AddonDetails';
import T from '@mui/material/Typography';
import MemberIcon from '@mui/icons-material/SupervisedUserCircle';
import { where, query } from 'firebase/firestore';
import { useTypedSelectorShallowEquals } from '../../hooks';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from '../../message';
import AddonInstalledChip from './AddonInstalledChip';
import { checkOrg, orgId as getOrgId, userType, orgPref, isDev } from '../../flags';
import Callout from '../Callout/Callout';
import { storage } from '../../utilities';
import Alert from '@mui/material/Alert';
import { EmptyState } from '../EmptyState/EmptyState';
import CreateAddonPanel from './CreateAddonPanel';
import { ProChip } from '../Version/VersionChip';
import { makeRef } from '../../firebase_utilities';
import { useHistory, useParams } from 'react-router-dom';
import { aiBlazeCommandsFilter, snippetOrPrompt } from '../../aiBlaze';
import { fullAppName } from '../../raw_flags';

function aiBlazeAddonFilter(addon) {
  // Remove addon without a single supported snippet for AI Blaze
  return !addon.data.active.data.snippets.every(snippet => !aiBlazeCommandsFilter(snippet.grants.commands));
}

function AddonsListing() {
  const { id: addonId } = /** @type {{ id: string }} */ (useParams());
  let [loading, setLoading] = useState(true);
  let [selectedAddon, setSelectedAddon] = useState(/** @type {AddonObjectInnerType} */ (null));
  let [addons, setAddons] = useState(null);
  const { push: navigate } = useHistory();

  let containerRef = useRef(/** @type {HTMLDivElement} */ (null)); // for scrolling on navigation

  let {
    local,
    isOrg,
    orgRole,
    orgId,
    userAddonsEnabled,
    allInstalled,
    installedUserAddons,
    installedOrgAddons
  } = useTypedSelectorShallowEquals((store)=> {
    /** @type {Object<string, GroupObjectType>} */
    let local = {};
    // addon.groups is a prefix -> group mapping. We need an addon_id -> group mapping.
    for (let group of Object.values(store.dataState.addons.groups)) {
      local[group.associated_addon_id] = group;
    }

    let isOrg = checkOrg(store);

    let installedUserAddons = store.userState?.addons ? Object.keys(store.userState.addons).filter(k => store.userState.addons[k].enabled) : [];
    let installedOrgAddons = store.orgState?.org?.addons ? Object.keys(store.orgState.org.addons).filter(k => store.orgState.org.addons[k].enabled) : [];

    return {
      local,
      isOrg,
      orgRole: userType(store),
      orgId: getOrgId(store),
      userAddonsEnabled: isOrg && ((store.orgState?.org) ? orgPref(store, 'userAddonsEnabled', true, undefined) : 'LOADING'),
      installedUserAddons: installedUserAddons,
      installedOrgAddons: installedOrgAddons,
      allInstalled: [...new Set(installedOrgAddons.concat(installedUserAddons))]
    };
  });


  useEffect(() => {
    const selectAddon = (addon) => {
      setSelectedAddon(addon);
      containerRef.current.scrollTo(0, 0);
      return;
    };
    /**
     * 
     * @param {any[]} addons 
     * @param {string} addonId 
     */
    const updateSelectedAddonOnLoad = (addons, addonId) => {
      if (!addonId) {
        return selectAddon(null);
      }
      let selectedAddon = addons.find(x => x.id === addonId);
      if (selectedAddon) {
        selectedAddon = Object.assign({ id: selectedAddon.id }, selectedAddon.data.active);
      }
      selectAddon(selectedAddon);
    };
    async function doLoad() {
      try {
        /** @type {any} */
        let addons = await storage.getQuery(query(makeRef('addons'), where('visibility', 'in', ['public', 'unlisted']), where('status', '==', 'active')));


        addons = addons.docs.map(x => ({
          id: x.id,
          data: x.data()
        }));

        if (addonId) {
          updateSelectedAddonOnLoad(addons, addonId);
        }

        setAddons(addons);
        setLoading(false);
      } catch (err) {
        toast('Error loading packs. ' + ((err && err.message) || ''), {
          duration: 6000,
          intent: 'danger'
        });
      }
    }
    if (!addons) {
      doLoad();
    } else {
      updateSelectedAddonOnLoad(addons, addonId);
    }
    // eslint-disable-next-line
  }, [addonId]);

  /**
   * @param {AddonObjectInnerType} addon
   */
  function handleSelect(addon) {
    navigate('/packs/' + addon.id);
  }


  let userAddonsBlocked = isOrg && orgRole !== 'owner' && !userAddonsEnabled;

  function renderInner() {
    if (loading || userAddonsEnabled === 'LOADING') {
      return <div style={{ paddingTop: '5vh', marginBottom: 80, justifyContent: 'center', display: 'flex' }}>
        <CircularProgress size={150} thickness={1.9} />
      </div>; 
    }

    if (userAddonsBlocked && !selectedAddon) {
      return <EmptyState
        icon="WARNING"
        title="User command packs disabled"
        description={`User command packs are disabled for your organization. Please contact your ${fullAppName} administrator.`}
      />;
    }

    if (!selectedAddon) {
      return <>
        <Alert severity="warning" style={{
          marginBottom: 16
        }}>
          Command packs are an experimental Beta feature. They may change significantly or be removed in the future.
        </Alert>
        <div style={{ display: 'flex', marginBottom: 16 }}>
          <div style={{ flex: 4 }}>
            <T>Command packs add new replacement commands to {fullAppName} extending the capabilities of your {snippetOrPrompt}s.</T>
          </div>
          {(isOrg && orgRole === 'owner') ? <div style={{ flex: 3, marginLeft: 16 }}>{(
            userAddonsEnabled ? <Callout><MemberIcon fontSize="small" style={{ opacity: .7, verticalAlign: 'middle', marginRight: 8, marginBottom: 2 }}/>Members of your organization can activate command packs for their own accounts. You can disable this capability for them in your Organization Options.</Callout> : <Callout><MemberIcon fontSize="small" style={{ opacity: .7, verticalAlign: 'middle' }}/> Members of your organization cannot activate command packs for their own accounts. You can enable this capability for them in your Organization Options.</Callout>
          )}</div> : null}
        </div>
        <>
          {allInstalled.length ? <div style={{
            marginBottom: 12,
            border: 'solid 1px #ddd',
            padding: '8px 10px',
            borderRadius: 8
          }}>
            <T style={{ display: 'inline-block', marginRight: 14, verticalAlign: 'middle' }} variant="body2" color="textSecondary">Active Packs</T> {allInstalled.map(installed => {
              let addon = addons.find(a => a.id === installed);
              const disabled = !aiBlazeAddonFilter(addon);
              let name;
              if (addon) {
                addon = Object.assign({ id: addon.id }, addon.data.active);
                name = addon.data.name;
              } else {
                name = (local[installed] && local[installed].name) || 'Private Pack';
              }
              return (local[installed] && <AddonInstalledChip
                key={installed}
                group={local[installed]}
                disabled={disabled}
                installedUserAddons={installedUserAddons}
                installedOrgAddons={installedOrgAddons}
                size="small"
                label={name}
                onClick={() => handleSelect(addon)}
                style={{
                  marginRight: 6,
                  marginLeft: 0
                }}
              />);
            })}
          </div> : null}
          <div style={{ textAlign: 'center' }}>
            {addons.filter(addon => {
              if (!aiBlazeAddonFilter(addon)) {
                return false;
              }
              // show if it is public
              if (addon.data.visibility === 'public') {
                return true;
              }
              // or we've enabled it
              if (allInstalled.includes(addon.id)) {
                return true;
              }
              return false;
            }).map((x, i) => <AddonCard
              key={i}
              addon={Object.assign({ id: x.id }, x.data.active)}
              installedUserAddons={installedUserAddons}
              installedOrgAddons={installedOrgAddons}
              onSelect={handleSelect}
              group={local[x.id]}
            />)}
          </div>

          {isDev() && <CreateAddonPanel />}
        </>
      </>;
    } else {
      return <AddonDetails
        allAddonsInstalled={allInstalled}
        installedUserAddons={installedUserAddons}
        installedOrgAddons={installedOrgAddons}
        orgId={orgId}
        orgRole={orgRole}
        addon={selectedAddon}
        group={local[selectedAddon.id]}
        userAddonsBlocked={userAddonsBlocked}
        onBack={() => {
          navigate('/packs');
        }}
      />;
    }
  }


  return <div
    ref={containerRef}
    style={{
      height: '100%',
      overflow: 'auto',
      paddingLeft: 8,
      paddingTop: 24,
      paddingRight: 24
    }}
  >
    <div>
      <T variant="h6">{selectedAddon ? <div style={{ marginBottom: 16, display: 'flex' }}>
        <span>{selectedAddon.data.name + ' Command Pack'}</span>
        <AddonInstalledChip
          group={local[selectedAddon.id]}
          installedUserAddons={installedUserAddons}
          installedOrgAddons={installedOrgAddons}
        />
        <div style={{ flex: 1 }}></div>
      </div> : <div style={{
        display: 'flex'
      }}>
        <div style={{ flex: 1, marginBottom: 16 }}>{fullAppName} Command Packs</div> <ProChip
          zoom={0.9}
          type="Pro"
        />
      </div>}</T>
        
    </div>
    {renderInner()}
  </div>;
}


const AddonsListingMemoized = React.memo(AddonsListing);
export default AddonsListingMemoized;