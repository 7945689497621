import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import ConfigureIcon from '@mui/icons-material/Settings';
import AddonConfigDialog from './AddonConfigDialog';
import { saveOrgAddonData, saveUserAddonData } from '../../data';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import { Tooltip } from '@mui/material';
import { fullAppName } from '../../raw_flags';

/**
 * @param {object} props 
 * @param {GroupObjectType} props.group
 * @param {'small'=} props.size
 * @param {string=} props.label
 * @param {function=} props.onClick
 * @param {object=} props.style
 * @param {string[]} props.installedUserAddons
 * @param {string[]} props.installedOrgAddons
 * @param {boolean=} props.disabled
 */
const AddonInstalledChip = (props) => {
  let [showConfig, setShowConfig] = useState(false);

  if (!props.group) {
    return null;
  }

  let addonId = props.group.associated_addon_id;
  let installSource = null;
  if (props.installedOrgAddons.includes(addonId)) {
    installSource = 'org';
  } else if (props.installedUserAddons.includes(addonId)) {
    installSource = 'user';
  }

  if (!installSource) {
    return null;
  }

  const hasConfigure = props.group.options.addon.config && props.group.options.addon.config.content;

  return <>
    {showConfig && <AddonConfigDialog
      group={props.group}
      onSave={(data) => {
        saveOrgAddonData(data.id, data.orgData);
        saveUserAddonData(data.id, data.userData);
      }}
      onClose={() => setShowConfig(false)}
    />}
    <Tooltip
      title={(installSource === 'user' ? 'Command pack active for you' : 'Command pack active for your organization') + (props.disabled ? ` (unsupported in ${fullAppName})` : '')}
    >
      <Chip
        style={Object.assign({
          marginLeft: 10
        }, props.style)}
        onClick={props.onClick && (() => {
          props.onClick();
        })}
        variant={props.disabled ? 'filled' : 'outlined'}
        icon={installSource === 'user' ? <PersonIcon /> : <BusinessIcon />}
        size={props.size}
        label={props.label || 'Active'}
        deleteIcon={hasConfigure && <ConfigureIcon />}
        onDelete={hasConfigure && ((e) => {
          e.preventDefault();
          setShowConfig(true);
        })}
      />
    </Tooltip>
  </>;
};
export default AddonInstalledChip;